import { BasicHero, Blocks, ErrorMsg, Header, SEO } from "@shared";
import { PageProps, graphql } from "gatsby";
import { usePageMeta, useSetVillageRelatedData } from "@util/logicHooks";

import { Query } from "@graphql-types";
import React from "react";
import RelatedNews from "@components/news/relatedNews";
import { VillageRelatedData } from "@util/types";
import { PageWidth } from "@util/standard";

type Data = VillageRelatedData & Query;
interface Props extends PageProps {
  data: Data;
}

export default function NewsTemplate({
  data: { sanityNews, sanityHeader, regionNews, villageNews, villagesNews, generalNews },
}: Props) {
  if (sanityNews == null) return <ErrorMsg data={sanityNews} msg="Error fetching data for page" />;

  const { slug, _type, seo, pageColour, title, categories, blocks, publishDate } = sanityNews;
  usePageMeta(pageColour?.colour?.hex, _type);
  useSetVillageRelatedData({ news: { regionNews, villageNews, villagesNews, generalNews } });

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <BasicHero
        heading={title}
        category={categories && categories[0]?.title}
        publishDate={publishDate}
      />
      <PageWidth>
        <Blocks data={blocks} sectionMargins="50px 0" />
      </PageWidth>
      {/* TODO: Add print function */}
      <RelatedNews margin="100px 0 150px 0" />
    </div>
  );
}

export const query = graphql`
  query newsQuery($slug: String, $region: String, $parentRegion: String, $village: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityNews(slug: { current: { eq: $slug } }) {
      ...sanityNews
    }
    villageNews: allSanityNews(
      filter: {
        villageRef: { slug: { current: { eq: $village } } }
        slug: { current: { ne: $slug } }
      }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }
    regionNews: allSanityNews(
      filter: {
        regionRef: { slug: { current: { in: [$region, $parentRegion] } } }
        slug: { current: { ne: $slug } }
      }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }
    generalNews: allSanityNews(
      filter: { categories: { elemMatch: { title: { eq: "General" } } } }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }

    villagesNews: allSanityNews(
      filter: {
        villages: { elemMatch: { slug: { current: { eq: $village } } } }
        slug: { current: { ne: $slug } }
      }
      limit: 2
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityNewsPreview
      }
    }
  }
`;
